import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "path": "/blog/cloudfront-invalidation-metrics",
  "date": "23 June 2022",
  "title": "CloudFront Invalidation Metrics",
  "wip": false,
  "draft": false,
  "summary": "Giving Skpr users insight into Cloudfront Invalidations",
  "author": "Karl Hepworth",
  "tag": "Observability",
  "tagColor": "blue",
  "tags": [{
    "name": "metrics"
  }, {
    "name": "CloudFront"
  }, {
    "name": "AWS"
  }]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`CloudFront plays a crucial part in getting content to users after you publish it, and we wanted Skpr customers to be
able to understand the impacts of purging its cache.`}</p>
    <p>{`So, in a recent release of Skpr, we shipped metrics for monitoring CloudFront Invalidations from your applications'
CloudWatch dashboard.`}</p>
    <h2>{`What are invalidations?`}</h2>
    <p>{`Invalidations are user-created requests to purge the cache for one or more paths.`}</p>
    <p>{`Purging content is usually triggered from the application - so that when content is updated, a user can see it
immediately. Inversely, the same process can be used to remove sensitive content from production.`}</p>
    <p>{`Skpr customers can also use the command line to create these invalidation requests outside application automation.`}</p>
    <h2>{`What invalidation metrics are available?`}</h2>
    <p>{`We've recently added CloudFront Invalidations to our application dashboards. This provides customers with greater
oversight of application patterns and behaviour.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/posts/20220623/diagram.png",
        "alt": "Diagram demonstrating workflow of cache invalidations"
      }}></img></p>
    <p>{`There are two metrics available. These are updated every five minutes:`}</p>
    <ul>
      <li parentName="ul">{`Total count of invalidations`}</li>
      <li parentName="ul">{`Total paths invalidated`}</li>
    </ul>
    <p><img parentName="p" {...{
        "src": "/images/posts/20220623/widget.png",
        "alt": "Screenshot of CloudWatch dashboard widget showing an example data-set and the relationship between request count\nand invalidation path count."
      }}></img></p>
    <h2>{`What can I do with this data?`}</h2>
    <p>{`CloudFront Invalidation metrics place control in the hands of developers. Invalidations can be triggered manually via
the command line, your project, or a manual entry in the AWS console, and these metrics are a consolidation of this
information.`}</p>
    <p>{`This data can indicate interesting behaviour in an application:`}</p>
    <h3>{`An error with automation`}</h3>
    <p>{`As an example, you may have several requests created by the application. You would then be able to see if the
invalidations were in line with expectations or if your application sends too many (or not enough) requests.`}</p>
    <h3>{`Information isn't available`}</h3>
    <p>{`If expected data is missing or a block of time is missing, this may indicate an error with another process that didn't
purge the cache as you expected. More significant releases tend to spike invalidation paths, and the data will reflect
this.`}</p>
    <h3>{`Potential cost optimizing`}</h3>
    <p>{`Cost is based on how many paths have been purged. The key is being able to identify any problems that have occurred.`}</p>
    <p>{`If there are issues, you have the opportunity to investigate these and save on the cost of running your applications.`}</p>
    <h2>{`In summary`}</h2>
    <p>{`CloudFront Invalidation metrics add to the transparency and rich insights already provided for Skpr customers.`}</p>
    <p>{`We hope these metrics add significant value to your Skpr experience and increase your understanding of your application
and its behaviour.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      